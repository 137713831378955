<template>
    <Modal
        name="forgotPassword"
        :title="$t('forgot_pass_title')"
    >
        <template>
            <p class="pbottom-small">
                {{ $t("forgot_pass_text") }}
            </p>
            <input
                v-model="email"
                type="email"
                name="login_email"
                :placeholder="$t('email_address')"
            />
        </template>

        <template #footer>
            <div class="grid">
                <div class="left" ></div>
                <div class="right">
                    <div class="button-group">
                        <app-button
                            :class="{ disabled: $v.$invalid }"
                            @click="handleResetPassword"
                        >
                            {{ $t("reset_password") }}
                        </app-button>
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import { userLoginService } from "@/services";
import {
    email,
    required
} from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import Modal from "@/components/modals/Modal";

export default {
    name: "ForgotPasswordModal",
    components: { Modal },
    mixins: [validationMixin],
    data() {
        return {
            email: "",
        };
    },
    methods: {
        handleResetPassword() {
            userLoginService.sendResetPasswordEmail(this.email);
            this.$notify({
                group: "app",
                type: "info",
                text: this.$t("send_password_reset_mail") + this.email
            });
            this.$modal.hide("forgotPassword");
        },
    },
    validations() {
        return {
            email: { required, email }
        };
    },
};
</script>

<style lang="scss" scoped>

</style>
