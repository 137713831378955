<template>
    <a
        class="button login-button"
        :class="{
            'disabled': busy || disabled,
        }"
        tabindex="0"
        @click.prevent="$emit('login')"
    >
        <div
            v-if="icon !== null"
            class="vendor-icon"
            v-html="icon"
        ></div>
        <span
            class="btn-text"
            :class="{
                'no-icon': icon === null,
            }"
        >
            <template v-if="!busy">{{ text }}</template>
            <template v-else><Spinner /></template>
        </span>
    </a>
</template>

<script>
import Spinner from "@/components/Spinner";
export default {
    name: "LoginButton",
    components: { Spinner },
    props: {
        text: {
            type: String,
            default: "",
        },
        icon: {
            type: String,
            default: null,
        },
        busy: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
    .login-button {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0.5rem 0.75rem;
        margin-left: 0 !important;
        margin-right: 0 !important;
        min-height: 40px;
        width: 100%;

        &:hover {
            background: var(--low-contrast, #{$light-grey});
            color: var(--foreground, #{$black});
        }

        .vendor-icon {
            width: 24px;
            height: 24px;
            flex: 0 0 auto;

        }

        &.apple {
            .vendor-icon::v-deep{
                svg path{
                    fill: var(--foreground);
                }
            }
        }

        .btn-text {
            padding-right: 24px;
            flex: 1 0 auto;
            display: block;
            width: 100%;
            text-align: center;

            &.no-icon {
                padding-left: 24px;
            }
        }

        &:not(.primary) {
            border: 1px solid var(--lowest-contrast);
            background: var(--background);
            color: var(--foreground);
        }
    }
</style>

<docs>
```vue
<login-button/>
```
</docs>
