<template>
    <div
        class="onboarding-progress-bar"
        :style="'--progress:' + progress"
    ></div>
</template>

<script>
export default {
    name: "OnboardingProgressBar",
    props: {
        progress: {
            type: Number,
            default: 0
        }
    }
};
</script>

<style lang="scss" scoped>
.onboarding-progress-bar {
    position: relative;
    width: 100%;
    height: .5rem;
    background: $low-contrast;
    border-radius: .25rem;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background: $primary;
        height: 100%;
        width: 100%;
        transform-origin: 0% 50%;
        transform: scaleX(calc(var(--progress) * 100%));
        transition: transform .5s ease-in-out;
    }
}
</style>
