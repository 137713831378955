<template>
    <div
        class="progress-dots flex"
        style="--dot-size: 0.5rem"
    >
        <div
            v-for="index in count"
            :key="index"
            class="dot"
            :class="{ 'complete': onlyCurrent? index === completed: index <= completed }"
        ></div>
    </div>
</template>

<script>
export default {
    name: "ProgressDots",
    props: {
        count: {
            type: Number,
            default: 5,
        },
        completed: {
            type: Number,
            default: 3,
        },
        spaceOut: {
            type: Boolean,
            default: false,
        },
        onlyCurrent: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.progress-dots {
    width: 100%;
    justify-content: center;

    .dot {
        width: var(--dot-size);
        height: var(--dot-size);
        border-radius: 50%;
        background: $medium-contrast;
        margin: var(--dot-size);

        &.complete {
            background: $primary;
        }
    }
}
</style>
