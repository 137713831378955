<template>
    <div class="portal-container">
        <transition
            name="fade"
            mode="out-in"
        >
            <authentication
                v-if="showAuthenticationView"
                :access-info="accessInfo"
                :join-intranet="intranetAccessController === null ? null : joinIntranet"
                :verification-forward-url="intranetAccessController && intranetAccessController.verificationForwardUrl"
            />
            <intranet-selection
                v-else-if="showIntranetSelection"
                :open-previous-intranet="intranetAccessController === null"
            />
        </transition>
        <confirm-modal/>
    </div>
</template>

<script>
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import {
    CURRENT_USER,
    LOGGED_IN
} from "@/store/auth/getters";
import Vue from "vue";
import {
    mapActions,
    mapGetters,
} from "vuex";
import IntranetSelection from "@/views/portal/IntranetSelection";
import Authentication from "@/views/portal/Authentication";
import { RELOAD_CURRENT_USER } from "@/store/auth/actions";
import { IntranetAccessMethod } from "@/views/portal/intranet-access";
import ConfirmModal from "@/components/modals/ConfirmModal";

export default Vue.extend({
    name: "Login",
    components: { ConfirmModal, IntranetSelection, Authentication },
    props: {
        /**
         * @type {IntranetAccessController}
         */
        intranetAccessController: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            accessInfo: null,
            loadingAccessInfo: true,
        };
    },
    computed: {
        ...mapGetters({
            loggedIn: AUTH_MODULE_NAME + LOGGED_IN,
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
        }),
        showAuthenticationView() {
            return (!this.loggedIn || !this.currentUser.emailVerified) && !this.loadingAccessInfo;
        },
        showIntranetSelection() {
            return this.loggedIn && this.currentUser.emailVerified && !this.loadingAccessInfo;
        },
    },
    methods: {
        ...mapActions({
            reloadCurrentUser: AUTH_MODULE_NAME + RELOAD_CURRENT_USER,
        }),
        async loadAccessInfo() {
            if (this.intranetAccessController === null) {
                this.accessInfo = null;
                this.loadingAccessInfo = false;
                return;
            }
            this.loadingAccessInfo = true;
            try {
                this.accessInfo = await this.intranetAccessController.getAccessInfo();
                if (this.loggedIn) {
                    if (this.intranetAccessController.accessMethod === IntranetAccessMethod.accessCode) {
                        await this.reloadCurrentUser();
                    }
                    await this.joinIntranet();
                }
                this.loadingAccessInfo = false;
            } catch (e) {
                console.error(e);
                this.$notify({
                    group: "app",
                    type: "error",
                    text: this.$t("error_invalid_invite_link"),
                });
                await this.$router.push({ path: "/login", params: { intranetAccessController: null } });
            }
        },
        async joinIntranet() {
            // If an access code is used we can't join until the email has been verified
            if (
                this.intranetAccessController.accessMethod === IntranetAccessMethod.accessCode &&
                !this.currentUser.emailVerified
            ) return;

            try {
                await this.intranetAccessController.joinIntranet();
                if (this.intranetAccessController.accessMethod === IntranetAccessMethod.invite) {
                    await this.reloadCurrentUser();
                }
            } catch (e) {
                this.$notify({
                    group: "app",
                    type: "error",
                    text: this.$t("join_error"),
                });
            }
            await this.$router.push({
                name: "dashboard",
                params: {
                    intranetId: this.accessInfo.intranetUid,
                    message: {
                        group: "app",
                        type: "success",
                        text: this.$t("join_success", [this.accessInfo.intranetName]),
                    },
                },
            });
        }
    },
    watch: {
        async intranetAccessController() {
            await this.loadAccessInfo();
        }
    },
    async mounted() {
        await this.loadAccessInfo();
    },
});
</script>

<style lang="scss" scoped>
</style>
