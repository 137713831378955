
import { Intranet } from "@backend/intranet/types";
import {
    defaultIntranetColors,
    IntranetColors
} from "@backend/theming/types";
import { getGlobalConfiguration } from "@web/global-config";

export class ThemeColors {
    public colors: IntranetColors = ThemeColors.DEFAULT_COLORS();

    public static of(intranet: Intranet): IntranetColors {
        return { ...intranet.colors };
    }

    public static DEFAULT_COLORS(): IntranetColors {
        return defaultIntranetColors(getGlobalConfiguration(), getGlobalConfiguration().theming_color_contrast_ratio_min);
    }
}
