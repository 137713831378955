<template>
    <div class="padding-large content">
        <div
            class="flex mbottom-small back fade-on-hover"
            @click="$emit('cancel')"
        >
            <Icon
                v-if="cancelable"
                name="arrow-left"
                class="mright-xsmall clickable"
                size="medium"
                color="var(--highest-contrast)"
            />
            <p class="h4 mbottom-none">
                {{ $i18n.t("back_to_overview") }}
            </p>
        </div>
        <h2>
            {{ title }}
        </h2>
        <p
            v-if="!cancelable"
            class="mbottom-medium"
        >
            {{ $t("welcome_to_linchpin_text_1") }}
        </p>
        <h4 class="mbottom-xsmall">
            {{ $t("add_workspace_join_title") }}
        </h4>
        <p>{{ $t("add_workspace_join_description") }}</p>
        <div class="form-group">
            <label for="access-code">{{ $t("add_workspace_join_input_label") }}</label>
            <input
                id="access-code"
                v-model="accessCode"
                type="text"
                spellcheck="false"
                :placeholder="$t('add_workspace_join_input_placeholder')"
                :class="{ error: accessCode !== null && $v.accessCode.$invalid }"
            />
        </div>
        <app-button
            :disabled="$v.$invalid"
            :busy="busy"
            type="primary"
            center
            @click="$emit('join', accessCode)"
        >
            {{ $t("add_workspace_join_button_label") }}
        </app-button>
        <div class="divider mbottom-medium mtop-medium">
            <span>{{ $t("or") }}</span>
        </div>
        <h4 class="mbottom-xsmall">
            {{ $t("add_workspace_create_title") }}
        </h4>
        <p>{{ $t("add_workspace_create_description") }}</p>
        <app-button
            :busy="busy"
            type="outline primary"
            center
            @click="$emit('create')"
        >
            {{ $t("add_workspace_create_button_label") }}
        </app-button>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
    minLength,
    required,
} from "vuelidate/lib/validators";
import { getGlobalConfiguration } from "@/global-config";

export default {
    name: "JoinOrCreateIntranetForm",
    mixins: [validationMixin],
    props: {
        cancelable: Boolean,
        busy: Boolean,
    },
    data() {
        return {
            accessCode: null,
        };
    },
    computed: {
        title() {
            if (this.cancelable) {
                return this.$t("add_workspace_title");
            }
            return this.$t("welcome_to_linchpin_title");
        },
    },
    validations() {
        return {
            accessCode: { required, minLength: minLength(getGlobalConfiguration().intranet_access_code_length) },
        };
    },
};
</script>

<style lang="scss" scoped>
.back {
    cursor: pointer;
    color: $highest-contrast;
}

#access-code {
    font-family: $font-family-mono;
}

.content {
    max-width: 32rem;
}
</style>
