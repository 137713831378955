<template>
    <div class="ptop-large pleft-large pright-large pbottom-medium content">
        <div
            class="flex mbottom-small back fade-on-hover"
            @click="$emit('cancel')"
        >
            <Icon
                name="arrow-left"
                class="mright-xsmall clickable"
                size="medium"
                color="var(--highest-contrast)"
            />
            <p class="h4 mbottom-none">
                {{ $t("back") }}
            </p>
        </div>
        <h2>
            {{ $t("invite_by_mail_title") }}
        </h2>
        <p
            class="mbottom-medium"
        >
            {{ $t("invite_by_mail_description", [workspaceName]) }}
        </p>
        <p>{{ $t("invite_by_mail_invite_colleagues") }}</p>
        <div class="mbottom-xsmall email-invite-list">
            <input
                v-for="(mail,index) in $v.emails.$each.$iter"
                :key="index"
                v-model.trim="mail.$model"
                type="email"
                :class="{
                    'error': mail.$error
                }"
                spellcheck="false"
                :placeholder="$t('example_mail')"
                @input="update()"
            />
        </div>
        <app-button
            class="mbottom-medium padding-xxsmall"
            icon="plus"
            :aria-label="$t('add_more')"
            @click="addMailInput"
        />
        <div class="flex">
            <app-button
                center
                type="outline primary"
                @click="$emit('cancel')"
            >
                <strong>{{ $t("back") }}</strong>
            </app-button>
            <app-button
                type="primary"
                :busy="busy"
                center
                @click="disableCodeBasedAccess; $emit('next')"
            >
                <strong>{{ $t("onboarding_lets_start") }}</strong>
            </app-button>
        </div>
        <ProgressDots
            :count="3"
            :completed="3"
            :only-current="true"
            class="mtop-medium"
        />
    </div>
</template>

<script>
import ProgressDots from "@web/components/ProgressDots";
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
import {
    email,
    required,
} from "vuelidate/lib/validators";
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import { CURRENT_USER } from "@web/store/auth/getters";
import { codeBasedIntranetAccessService } from "@web/services";

export default {
    name: "InviteByMailForm",
    components: { ProgressDots },
    mixins: [validationMixin],
    props: {
        intranetUid: {
            type: String,
        },
        workspaceName: {
            type: String,
            required: true,
        },
        initialEmails: Array,
        busy: Boolean,
        /** @type {IntranetColors} */
        colors: Object,
    },
    data() {
        return {
            loadingAccessCode: true,
            emails: this.initialEmails || [
                "",
                "",
            ],
            accessCode: undefined,
            keepCodeBasedAccessEnabled: false,
        };
    },
    computed: {
        ...mapGetters({
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
        }),
    },
    methods: {
        update() {
            this.$emit("update", this.emails);
        },
        addMailInput() {
            this.emails.push("");
        },
        async disableCodeBasedAccess() {
            await codeBasedIntranetAccessService.disableCodeBasedAccess(this.intranetUid);
        },
    },
    validations: {
        emails: {
            $each: {
                required,
                email,
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.back {
    cursor: pointer;
    color: $highest-contrast;
}

.btn-content {
    margin: 0.3rem;
}

.content {
    max-width: 32rem;
}

.email-invite-list {
    max-width: 50vh;
    overflow-y: scroll;
}

input[type="email"] {
    margin-bottom: $spacing-xsmall;

    &:last-child {
        margin-bottom: 0;
    }
}
</style>
