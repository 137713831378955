<template>
    <div class="login-card-container">
        <div
            v-if="accessInfo === null"
            class="vendor-icon centered"
            v-html="require('@web/assets/linchpin.svg')"
        ></div>
        <div
            v-else
            class="vendor-icon centered"
        >
            <img :src="accessInfo.intranetLogo" />
        </div>
        <div class="card auth-box padding-medium ptop-medium depth-4">
            <TransitionExpand>
                <SignUpSuccessful
                    v-if="state === AuthenticationState.SIGN_UP_SUCCESSFUL"
                />
                <SignUpForm
                    v-else-if="isInSignUpMode"
                    :access-info="accessInfo"
                    :apple-login-enabled="appleLoginEnabledForEnv"
                    :on-submit="redeemInviteIfAvailable"
                    :verification-forward-url="verificationForwardUrl"
                    @open-login="state = AuthenticationState.LOGIN"
                />
                <LoginForm
                    v-else
                    :access-info="accessInfo"
                    :apple-login-enabled="appleLoginEnabledForEnv"
                    :on-submit="redeemInviteIfAvailable"
                    @forgot-password="$modal.show('forgotPassword')"
                    @open-sign-up="state = AuthenticationState.SIGN_UP"
                />
            </TransitionExpand>
        </div>

        <forgot-password-modal />
    </div>
</template>

<script>
import SignUpSuccessful from "@/views/portal/SignUpSuccessful";
import SignUpForm from "@/views/portal/SignUpForm";
import TransitionExpand from "@/components/TransitionExpand";
import { appleWebLoginEnabled } from "@/firebaseConfig";
import LoginForm from "@/views/portal/LoginForm";
import { AuthenticationMethod } from "@backend/invite/types";
import ForgotPasswordModal from "@/views/portal/ForgotPasswordModal";

const AuthenticationState = {
    LOGIN: "LOGIN",
    SIGN_UP: "SIGN_UP",
    SIGN_UP_SUCCESSFUL: "SIGN_UP_SUCCESSFUL"
};

export default {
    name: "Authentication",
    components: { ForgotPasswordModal, SignUpSuccessful, SignUpForm, LoginForm, TransitionExpand },
    props: {
        accessInfo: {
            type: Object,
            default: null,
        },
        joinIntranet: {
            type: Function,
            default: null,
        },
        verificationForwardUrl: String,
    },
    data() {
        return {
            state: null,
            AuthenticationState,
        };
    },
    computed: {
        appleLoginEnabledForEnv() {
            return appleWebLoginEnabled;
        },
        isInSignUpMode() {
            if (this.state !== null) {
                return this.state === AuthenticationState.SIGN_UP;
            }
            if (this.$route.query.verified) {
                return false;
            }
            if (this.accessInfo !== null && (!this.accessInfo.grantee || !this.accessInfo.grantee.authMethod)) {
                return true;
            }
            return false;
        },
    },
    methods: {
        async redeemInviteIfAvailable(usedAuthenticationMethod) {
            if (this.joinIntranet === null) {
                if (usedAuthenticationMethod === AuthenticationMethod.email) {
                    this.state = AuthenticationState.SIGN_UP_SUCCESSFUL;
                }
                return;
            }

            await this.joinIntranet();
            if (!this.accessInfo.grantee || !this.accessInfo.grantee.authMethod) {
                this.state = AuthenticationState.SIGN_UP_SUCCESSFUL;
            }
        },
    },
};
</script>

<style lang="scss">
.vendor-icon {
    svg, img {
        max-width: 18rem;
        max-height: 6rem;
    }
}
</style>

<style lang="scss" scoped>
.vendor-icon {
    margin: 0 auto 2rem auto;
}

.login-card-container {
    margin: auto;
    padding: 3rem 0;
}

.vendor-icon > * {
    max-width: 18rem;
    max-height: 6rem;
}

.auth-box {
    width: 340px;

    @media screen and (min-width: 2160px) {
        width: 480px;
    }
}
</style>
