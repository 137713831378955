<template>
    <SplitTutorialStep
        :progress-dot-count="3"
        :progress-dot-completed="1"
    >
        <template #left>
            <h2>
                {{ $t("create_workspace_title") }}
            </h2>
            <p>{{ $t("create_workspace_description") }}</p>
            <div class="form-group">
                <label for="company-name">{{ $t("create_workspace_company_name_label") }}</label>
                <input
                    id="company-name"
                    v-model="companyName"
                    type="text"
                    :placeholder="$t('create_workspace_company_name_placeholder')"
                    :class="{ error: companyName !== null && $v.companyName.$invalid }"
                    @input="update()"
                />
            </div>
            <div class="form-group">
                <label for="workspace-name">{{ $t("create_workspace_workspace_name_label") }}</label>
                <input
                    id="workspace-name"
                    :value="workspaceName"
                    type="text"
                    :placeholder="$t('create_workspace_workspace_name_placeholder')"
                    :class="{ error: workspaceName !== null && $v.workspaceName.$invalid }"
                    @input="updateCustomWorkspaceName($event.target.value)"
                />
            </div>
            <div class="form-group">
                <label for="company-size">{{ $t("create_workspace_company_size_label") }}</label>
                <EverestSelect
                    id="company-size"
                    :value="initialCompanySize"
                    :options="companySizes"
                    :key-provider="val => val.value"
                    :display-name-provider="val => val.display"
                    :selected-display-name-provider="val => val.display"
                    :placeholder="$t('create_workspace_company_size_placeholder')"
                    @input="updateCompanySize($event)"
                />
            </div>
            <div class="form-group">
                <input
                    id="prefill"
                    v-model="prefill"
                    type="checkbox"
                    @change="update()"
                />
                <label for="prefill">{{ $t("create_workspace_demo_content_label") }}</label>
            </div>
            <div class="flex">
                <app-button
                    center
                    type="outline primary"
                    @click="$emit('cancel')"
                >
                    <strong>{{ $t("cancel") }}</strong>
                </app-button>
                <app-button
                    type="primary"
                    :disabled="$v.$invalid"
                    :busy="busy"
                    center
                    @click="$emit('next')"
                >
                    <strong>{{ $t("next") }}</strong>
                </app-button>
            </div>
        </template>
        <template #right>
            <div
                class="skeleton"
                v-html="intranetSkeleton"
            ></div>
            <p class="small-text">
                {{ $t("create_workspace_preview_hint") }}
            </p>
        </template>
    </SplitTutorialStep>
</template>

<script>
import { validationMixin } from "vuelidate";
import intranetSkeleton from "@web/assets/onboarding/skeleton_intranet.svg";
import SplitTutorialStep from "@web/components/onboarding/SplitTutorialStep";
import {
    minLength,
    required,
} from "vuelidate/lib/validators";
import EverestSelect from "@web/components/EverestSelect";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";

export default {
    name: "CreateIntranetForm",
    components: { EverestSelect, SplitTutorialStep },
    mixins: [validationMixin],
    props: {
        busy: Boolean,
        initialWorkspaceName: String,
        initialCompanyName: String,
        initialCompanySize: Object,
        initialPrefill: Boolean,
    },
    data() {
        return {
            companyName: null,
            customWorkspaceName: null,
            prefill: true,
            companySize: undefined,
            companySizes: [
                { display: this.$t("less_than_ten"), value: "< 10" },
                { display: "10-50", value: "10-50" },
                { display: "51-100", value: "51-100" },
                { display: "101-250", value: "101-250" },
                { display: "251-500", value: "251-500" },
                { display: this.$t("more_than_fivehundred"), value: "> 500" },
            ],
            intranetSkeleton,
        };
    },
    computed: {
        workspaceName() {
            if (this.customWorkspaceName === null) {
                return this.companyName;
            }
            return this.customWorkspaceName;
        },
    },
    validations() {
        return {
            companyName: { required, minLength: minLength(3) },
            workspaceName: { required, minLength: minLength(3) },
        };
    },
    mounted() {
        if (this.initialCompanyName && this.initialCompanyName.length > 0) this.companyName = this.initialCompanyName;
        if (this.initialCompanySize && this.initialCompanySize.length > 0) this.companySize = this.initialCompanySize;
        if (this.initialWorkspaceName && this.initialWorkspaceName.length > 0 && this.initialWorkspaceName !== this.initialCompanyName) this.customWorkspaceName = this.initialWorkspaceName;
        if (this.initialPrefill !== undefined) this.prefill = this.initialPrefill;
        if (this.initialWorkspaceName.length < 3) analytics.log(getGlobalConfiguration().analytics_event_name_started_intranet_creation);
    },
    methods: {
        update() {
            this.$emit("update", this.companyName, this.workspaceName, this.prefill, this.companySize);
        },
        updateCustomWorkspaceName(customWorkspaceName) {
            this.customWorkspaceName = customWorkspaceName;
            this.update();
        },
        updateCompanySize(companySize) {
            this.companySize = companySize;
            this.update();
        }
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .select-trigger {
        padding: $spacing-xsmall;
        width: 100%;
        border-radius: $border-radius-small;
        border: 1px solid $low-contrast;

        option[disabled="disabled"] {
            opacity: .5;
        }
    }

    .skeleton {
        border-radius: $border-radius-small;
        box-shadow: $depth-2;
    }

    .select {
        width: 100% !important;
    }

    .fill-primary {
        fill: $primary;
    }

    .fill-bg {
        fill: $background;
    }

    .fill-medium {
        fill: $medium-contrast;
    }

    .fill-low {
        fill: $low-contrast;
    }

    .fill-lowest {
        fill: $lowest-contrast;
    }

    .stroke-bg {
        stroke: $background;
    }

    .stroke-lowest {
        stroke: $lowest-contrast;
    }
}
</style>
