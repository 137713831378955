<template>
    <form @keyup.enter="signUpWithEmailAndPassword()">
        <template v-if="accessInfo === null">
            <h3 class="centered">
                {{ title }}
            </h3>
        </template>
        <template v-else>
            <h4 class="signup-headline centered">
                {{ title }}
            </h4>
            <p class="centered meta">
                {{ subtitle }}
            </p>
        </template>

        <div class="form-group">
            <label for="signup-email">{{ $t("email") }}</label>
            <input
                id="signup-email"
                v-model="email"
                type="email"
                name="signup-email"
                autocomplete="email"
                :placeholder="$t('email_address')"
                :class="{ error: hasError('email') }"
                :disabled="isEmailSet"
            />
        </div>

        <div class="form-group">
            <label for="full-name">{{ $t("signup_full_name") }}</label>
            <input
                id="full-name"
                v-model="fullName"
                type="text"
                name="full-name"
                autocomplete="name"
                :placeholder="$t('your_name')"
                :class="{ error: $v.fullName && $v.fullName.$error }"
            />
        </div>

        <div class="form-group">
            <label for="password">{{ $t("password") }}</label>
            <password
                id="password"
                v-model="password"
                name="password"
                class="password-group"
                default-class="password"
                autocomplete="new-password"
                :badge="false"
                :placeholder="$t('password')"
                :show-strength-meter="true"
                :class="{ error: hasError('password') }"
                @score="updateScore"
                @blur="handlePasswordVal()"
            />
            <TransitionExpand>
                <div
                    :key="passwordScoreInfo"
                    :class="['meta', 'password-score-info', 'score-' + passwordScore]"
                >
                    {{ passwordScoreInfo }}
                </div>
            </TransitionExpand>
        </div>
        <div class="form-group">
            <label for="confirmPassword">{{ $t("confirm_password") }}</label>
            <input
                id="confirmPassword"
                v-model="confirmPassword"
                type="password"
                name="confirmPassword"
                autocomplete="new-password"
                :placeholder="$t('confirm_password')"
                :class="{
                    error: $v.confirmPassword && $v.confirmPassword.$error
                }"
                @blur="handleConfirmPasswordVal()"
            />
        </div>

        <div class="form-group flex">
            <div>
                <input
                    id="confirm-tos"
                    v-model="confirmTos"
                    type="checkbox"
                    name="confirm-tos"
                    tabindex="-1"
                />
                <label
                    for="confirm-tos"
                    class="confirm-tos-label"
                >
                    {{ $t("signup_tos_1") }}
                    <a
                        target="blank"
                        :href="this.$t('login_terms_and_conditions_href')"
                        tabindex="-1"
                    >
                        {{ $t("signup_tos_2") }}
                    </a>
                    {{ $t("signup_tos_3") }}
                </label>
            </div>
        </div>

        <div class="form-group">
            <LoginButton
                class="primary mbottom-small"
                :text="$t('signup')"
                :busy="creatingAccount"
                :disabled="$v.$invalid"
                @login="signUpWithEmailAndPassword"
            />
        </div>

        <div class="divider">
            <span>{{ $t("or") }}</span>
        </div>

        <LoginButton
            class="depth-1 mbottom-small"
            :text="$t('signup_google')"
            :icon="require(`@/assets/vendors/google-icon.svg`)"
            :busy="loggingInWithGoogle"
            @login="logInWithGoogle"
        />
        <LoginButton
            v-if="appleLoginEnabled"
            class="depth-1 apple mbottom-small"
            :text="$t('signup_apple')"
            :icon="require(`@/assets/vendors/apple-icon.svg`)"
            :busy="loggingInWithApple"
            @login="logInWithApple"
        />

        <div
            v-if="accessInfo === null || !accessInfo.grantee"
            class="centered"
        >
            {{ $t("signup_already_have_account") }}
            <a
                class="link-button"
                href="#"
                @click="$emit('open-login')"
            >
                {{ $t("login") }}
            </a>
        </div>
    </form>
</template>

<script>
import LoginButton from "@/components/LoginButton";
import { validationMixin } from "vuelidate";
import {
    email,
    helpers,
    minLength,
    required,
    sameAs,
} from "vuelidate/lib/validators";
import { getGlobalConfiguration } from "@/global-config";
import {
    mapActions,
    mapGetters
} from "vuex";
import Password from "vue-password-strength-meter";
import { AUTH_MODULE_NAME } from "@/store/auth/auth";
import {
    OPEN_LOGIN_POPUP_APPLE,
    OPEN_LOGIN_POPUP_GOOGLE,
    SIGN_UP_WITH_EMAIL_AND_PASSWORD,
} from "@/store/auth/actions";
import {
    BUSY_LOGGING_IN_WITH_APPLE,
    BUSY_LOGGING_IN_WITH_GOOGLE,
} from "@/store/auth/getters";
import TransitionExpand from "@/components/TransitionExpand";
import { AuthenticationMethod } from "@backend/invite/types";

const passwordRegex = new RegExp("[" + getGlobalConfiguration().password_allowed_chars + "]", "g");
const passwordQuality = helpers.regex(
    "password",
    new RegExp("^[" + getGlobalConfiguration().password_allowed_chars + "]+$")
);

export default {
    name: "SignUpForm",
    components: { TransitionExpand, LoginButton, Password },
    mixins: [validationMixin],
    props: {
        appleLoginEnabled: {
            type: Boolean,
            default: true,
        },
        /**
         * @type {IntranetAccessInfo}
         */
        accessInfo: {
            type: Object,
            default: null,
        },
        onSubmit: {
            type: Function,
            default: null,
        },
        verificationForwardUrl: String,
    },
    data() {
        return {
            email: "",
            fullName: "",
            password: "",
            confirmPassword: "",
            confirmTos: false,
            passwordScore: null,
            passwordScoreInfo: "",
            creatingAccount: false,
        };
    },
    validations() {
        return {
            email: { required, email },
            fullName: { required },
            password: { required, minLength: minLength(8), passwordQuality },
            confirmPassword: {
                required,
                sameAs: sameAs("password"),
            },
            confirmTos: {
                sameAs: sameAs(() => true),
            }
        };
    },
    computed: {
        ...mapGetters({
            loggingInWithGoogle: AUTH_MODULE_NAME + BUSY_LOGGING_IN_WITH_GOOGLE,
            loggingInWithApple: AUTH_MODULE_NAME + BUSY_LOGGING_IN_WITH_APPLE
        }),
        title() {
            if (!this.accessInfo) {
                return this.$t("signup_title");
            }
            if (!this.accessInfo.grantee) {
                return this.$t("signup_or_login_title");
            }
            return this.$t("invite_headline");
        },
        subtitle() {
            if (!this.accessInfo.grantee) {
                return this.$t("signup_or_login_subtitle", [this.accessInfo.intranetName]);
            }
            return this.$t("invite_subline_signup", [this.accessInfo.intranetName]);
        },
        isEmailSet() {
            return this.accessInfo && this.accessInfo.grantee !== undefined;
        }
    },
    mounted() {
        if (this.isEmailSet) {
            this.email = this.accessInfo.grantee.email;
        }
    },
    methods: {
        ...mapActions({
            _logInWithGoogle: AUTH_MODULE_NAME + OPEN_LOGIN_POPUP_GOOGLE,
            _logInWithApple: AUTH_MODULE_NAME + OPEN_LOGIN_POPUP_APPLE,
            _signUpWithEmailAndPassword: AUTH_MODULE_NAME + SIGN_UP_WITH_EMAIL_AND_PASSWORD,
        }),
        handlePasswordVal() {
            if (!this.$v.password.minLength) {
                this.$notify({
                    group: "app",
                    type: "info",
                    text: `${this.$t("password_too_short")}`
                });
            }
            if (!this.$v.password.passwordQuality) {
                const disallowedCharacters = this.password.replaceAll(passwordRegex, "")
                    .split("")
                    .map((c) => c.replace(" ", "space"))
                    .join(" ");
                this.$notify({
                    group: "app",
                    type: "info",
                    duration: 6000,
                    text: `${this.$t("password_disallowed_characters", [disallowedCharacters])}`
                });
            }
        },
        handleConfirmPasswordVal() {
            !this.$v.confirmPassword.sameAs &&
            this.$notify({
                group: "app",
                type: "info",
                text: this.$t("password_does_not_match")
            });
        },
        updateScore(score) {
            this.passwordScore = score;
            if (score === null) {
                this.passwordScoreInfo = "";
                return;
            }
            this.passwordScoreInfo = this.$t("password_score_" + score);
        },
        hasError(varname) {
            return (
                this.$v[varname] && this.$v[varname].$model && this.$v[varname].$invalid
            );
        },
        async signUpWithEmailAndPassword() {
            if (this.$v.$invalid) {
                return;
            }
            try {
                this.creatingAccount = true;
                await this._signUpWithEmailAndPassword({
                    email: this.isEmailSet ? this.accessInfo.grantee.email : this.email,
                    displayName: this.fullName,
                    password: this.password,
                    requireEmailVerification: !this.accessInfo ||
                        !this.accessInfo.grantee,
                    verificationForwardUrl: this.verificationForwardUrl,
                });
                if (this.onSubmit !== null) {
                    await this.onSubmit(AuthenticationMethod.email);
                }
                this.password = "";
                this.confirmPassword = "";
            } catch (e) {
                console.error(e);
                switch (e) {
                    case "auth/operation-not-allowed":
                        this.$notify({
                            group: "app",
                            type: "error",
                            text: this.$t("signup_with_username_password_disabled")
                        });
                        break;
                    case "auth/email-already-in-use":
                        this.$notify({
                            group: "app",
                            type: "error",
                            text: this.$t("email_already_in_use")
                        });
                        break;
                    case "auth/invalid-email":
                        this.$notify({
                            group: "app",
                            type: "error",
                            text: this.$t("email_not_valid")
                        });
                        break;
                    case "auth/weak-password":
                        this.$notify({
                            group: "app",
                            type: "error",
                            text: this.$t("choose_stronger_password")
                        });
                        break;
                    default:
                        this.$notify({
                            group: "app",
                            type: "error",
                            text: this.$t("unknown_error")
                        });
                }
            } finally {
                this.creatingAccount = false;
            }
        },
        logInWithGoogle() {
            return this._socialSignUp(() => this._logInWithGoogle(), AuthenticationMethod.google);
        },
        logInWithApple() {
            return this._socialSignUp(() => this._logInWithApple(), AuthenticationMethod.apple);
        },
        async _socialSignUp(signUpFunc, authMethod) {
            if (!this.confirmTos) {
                this.$notify({
                    group: "app",
                    type: "error",
                    text: this.$t("signup_tos_not_confirmed")
                });
                return;
            }
            await signUpFunc();
            if (this.onSubmit !== null) {
                await this.onSubmit(authMethod);
            }
        }
    }
};
</script>

<style lang="scss">
.Password .Password__strength-meter {
    margin-bottom: 0.25rem;

    [data-score='0'] {
        background: var(--warning-dark);
    }
    [data-score='1'] {
        background: var(--warning);
        width: 41%;
    }
    [data-score='2'] {
        background: var(--info);
        width: 59%
    }
    [data-score='3'] {
        background: var(--primary-light);
    }
    [data-score='4'] {
        background: var(--primary);
    }

    &:after,
    &:before {
        border-color: var(--background);
    }
}
</style>

<style lang="scss" scoped>
.confirm-tos-label > a, a.link-button {
    color: var(--primary);
    text-decoration: none;
}

a.link-button {
    font-weight: 700;
}

.grid {
    align-items: stretch;
}

.Password {
    width: 100%;
    max-width: inherit;
}

.password-score-info {
    color: var(--foreground, #{$black});
    opacity: 1;
    &.score-0 {
        color: var(--warning-dark);
    }
    &.score-1 {
        color: var(--warning);
    }
    &.score-2 {
        color: var(--info);
    }
    &.score-3 {
        color: var(--primary-light);
    }
    &.score-4 {
        color: var(--primary);
    }
}

.password {
    font-size: inherit;
}

.signup-headline {
    margin-bottom: .2rem;
}
</style>
