


















































































































import { LOAD_AVAILABLE_INTRANETS } from "@web/store/intranet/actions";
import {
    INTRANET_MODULE_NAME,
    LAST_VISITED_INTRANET,
} from "@web/store/intranet/intranet";
import Vue from "vue";
import {
    mapActions,
    mapState,
} from "vuex";
import { domainBasedIntranetAccessService } from "@web/services";
import { Intranet } from "@backend/intranet/types";
import {
    LOGOUT_FROM_FIREBASE,
    RELOAD_CURRENT_USER,
} from "@web/store/auth/actions";
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import AddIntranet from "@web/views/portal/AddIntranet.vue";

export default Vue.extend<any, any, any, any>({
    name: "AvailableIntranets",
    components: { AddIntranet },
    props: {
        openPreviousIntranet: Boolean,
    },
    data() {
        return {
            activeId: null,
            loading: true,
            invitedIntranets: [],
            redeeming: false,
            creationMode: false,
        };
    },
    methods: {
        ...mapActions({
            loadIntranets: INTRANET_MODULE_NAME + LOAD_AVAILABLE_INTRANETS,
            reloadIntranetList: INTRANET_MODULE_NAME + LOAD_AVAILABLE_INTRANETS,
            reloadCurrentUser: AUTH_MODULE_NAME + RELOAD_CURRENT_USER,
            logOut: AUTH_MODULE_NAME + LOGOUT_FROM_FIREBASE,
        }),
        enterIntranet(clickedId: number, intranetUid: string) {
            this.activeId = clickedId;
            setTimeout(() => this.$router.push("/intranet/" + intranetUid), 0);
        },
        intranetHasNoLogo(intranet: Intranet) {
            return intranet.logo === "default/logo/logo.png";
        },
        async init() {
            this.loading = true;
            try {
                await Promise.all([
                    this.loadIntranets(),
                    this.loadDomainBasedInvites(),
                ]);
                if (this.availableIntranets.length === 0 && this.invitedIntranets.length === 1) {
                    await this.redeemInvite(this.invitedIntranets[0].uid);
                    return;
                }
                if (!this.hasUserNavigatedToAnIntranet) {
                    this.openPreviouslyOpenedIntranet();
                }
            } finally {
                this.loading = false;
            }
        },
        async loadDomainBasedInvites() {
            const { data } = await this.fetchDomainBasedInvites();
            this.invitedIntranets = data;
        },
        async fetchDomainBasedInvites() {
            try {
                return await domainBasedIntranetAccessService.getAvailableIntranets();
            } catch (error) {
                if (error.response !== undefined && error.response.status === 401) {
                    await this.reloadCurrentUser();
                    return await domainBasedIntranetAccessService.getAvailableIntranets();
                }
                throw error;
            }
        },
        openPreviouslyOpenedIntranet() {
            if (!this.openPreviousIntranet) {
                return;
            }

            const lastVisitedIntranet = localStorage.getItem(
                LAST_VISITED_INTRANET,
            );
            const availableIntranets = this.availableIntranets.map(
                (i: Intranet) => i.uid,
            );

            if (
                lastVisitedIntranet !== null &&
                availableIntranets.includes(lastVisitedIntranet)
            ) {
                this.enterIntranet(123, lastVisitedIntranet);
            }
        },
        async redeemInvite(uid: string) {
            this.redeeming = true;
            await domainBasedIntranetAccessService
                .redeemAccess(uid)
                .then(async() => {
                    await this.$router.push("/intranet/" + uid);
                    await this.reloadIntranetList();
                })
                .catch(e => {
                    this.$notify({
                        group: "app",
                        type: "error",
                        text: this.$t(
                            "error_redeeming_domain_based_intranet_access",
                        ),
                    });
                    console.error(e);
                })
                .finally(() => {
                    this.redeeming = false;
                });
        },
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, [
            "availableIntranets",
            "invites",
            "permissions",
            "loadingIntranets",
            "hasUserNavigatedToAnIntranet",
        ]),
        noIntranetsAvailable() {
            return !(this.availableIntranets.length > 0 || this.invitedIntranets.length > 0);
        },
        isCreating() {
            return this.creationMode || this.noIntranetsAvailable;
        },
    },
    created() {
        this.init();
    },
});
