<template>
    <form @keyup.enter="loginWithEmailAndPassword()">
        <template v-if="accessInfo === null">
            <h3 class="centered">
                {{ title }}
            </h3>
        </template>
        <template v-else>
            <h4 class="login-headline centered">
                {{ title }}
            </h4>
            <p class="centered meta">
                {{ subtitle }}
            </p>
        </template>

        <div
            v-if="provideEmailLogin"
            class="email-sign-in"
        >
            <div class="form-group">
                <label for="login_email">{{ $t("email") }}</label>
                <input
                    id="login_email"
                    v-model="email"
                    type="email"
                    name="login_email"
                    autocomplete="email"
                    :placeholder="$t('email_address')"
                    :disabled="accessInfo && accessInfo.grantee"
                />
            </div>
            <div class="form-group">
                <label for="login_password">{{ $t("password") }}</label>
                <input
                    id="login_password"
                    v-model="password"
                    type="password"
                    name="login_password"
                    autocomplete="current-password"
                    :placeholder="$t('password')"
                />
                <div class="forgot-password">
                    <a
                        class="link-button"
                        href="#"
                        @click="$emit('forgot-password')"
                    >
                        {{ $t("forgot_password") }}
                    </a>
                </div>
            </div>

            <div class="form-group">
                <LoginButton
                    class="primary mbottom-small"
                    :text="$t('login')"
                    :busy="loggingIn"
                    :disabled="$v.$invalid"
                    @login="loginWithEmailAndPassword"
                />
            </div>
        </div>

        <div
            v-if="showAllLoginOptions"
            class="divider"
        >
            <span>{{ $t("or") }}</span>
        </div>

        <LoginButton
            v-if="provideGoogleLogin"
            class="depth-1 mbottom-small"
            :text="$t('login_google')"
            :icon="require(`@/assets/vendors/google-icon.svg`)"
            :busy="loggingInWithGoogle"
            @login="logInWithGoogle"
        />
        <LoginButton
            v-if="provideAppleLogin"
            class="depth-1 apple mbottom-small"
            :text="$t('login_apple')"
            :icon="require(`@/assets/vendors/apple-icon.svg`)"
            :busy="loggingInWithApple"
            @login="logInWithApple"
        />

        <div
            v-if="!accessInfo || !accessInfo.grantee"
            class="signup-link"
        >
            {{ $t("login_no_account") }}
            <a
                class="link-button"
                href="#"
                @click="$emit('open-sign-up')"
            >
                {{ $t("signup") }}
            </a>
        </div>
    </form>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import { getGlobalConfiguration } from "@/global-config";
import { AUTH_MODULE_NAME } from "@/store/auth/auth";
import {
    OPEN_LOGIN_POPUP_APPLE,
    OPEN_LOGIN_POPUP_GOOGLE,
    SIGN_IN_WITH_EMAIL_AND_PASSWORD
} from "@/store/auth/actions";
import { validationMixin } from "vuelidate";
import {
    email,
    minLength,
    required
} from "vuelidate/lib/validators";
import {
    BUSY_LOGGING_IN_WITH_APPLE,
    BUSY_LOGGING_IN_WITH_GOOGLE
} from "@/store/auth/getters";
import { AuthenticationMethod } from "@backend/invite/types";
import LoginButton from "@/components/LoginButton";

export default {
    name: "LoginForm",
    components: { LoginButton },
    mixins: [validationMixin],
    props: {
        appleLoginEnabled: {
            type: Boolean,
            default: true,
        },
        /**
         * @type {IntranetAccessInfo}
         */
        accessInfo: {
            type: Object,
            default: null,
        },
        onSubmit: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            email: "",
            password: "",
            loggingIn: false,
        };
    },
    computed: {
        ...mapGetters({
            loggingInWithGoogle: AUTH_MODULE_NAME + BUSY_LOGGING_IN_WITH_GOOGLE,
            loggingInWithApple: AUTH_MODULE_NAME + BUSY_LOGGING_IN_WITH_APPLE
        }),
        title() {
            if (!this.accessInfo || this.accessInfo.grantee === undefined) {
                return this.$t("login");
            }
            return this.$t("invite_headline");
        },
        subtitle() {
            if (this.accessInfo.grantee === undefined) {
                return this.$t("signup_or_login_subtitle", [this.accessInfo.intranetName]);
            }
            return this.$t("invite_subline_login", [this.accessInfo.intranetName]);
        },
        showAllLoginOptions() {
            if (!this.accessInfo) return true;
            return !this.accessInfo.grantee || !this.accessInfo.grantee.authMethod;
        },
        provideEmailLogin() {
            return this.showAllLoginOptions || this.accessInfo.grantee.authMethod === AuthenticationMethod.email;
        },
        provideGoogleLogin() {
            return this.showAllLoginOptions || this.accessInfo.grantee.authMethod === AuthenticationMethod.google;
        },
        provideAppleLogin() {
            return this.appleLoginEnabled &&
                (this.showAllLoginOptions || this.accessInfo.grantee.authMethod === AuthenticationMethod.apple);
        },
    },
    methods: {
        ...mapActions({
            _logInWithGoogle: AUTH_MODULE_NAME + OPEN_LOGIN_POPUP_GOOGLE,
            _logInWithApple: AUTH_MODULE_NAME + OPEN_LOGIN_POPUP_APPLE,
            _logInWithEmailAndPassword:
                AUTH_MODULE_NAME + SIGN_IN_WITH_EMAIL_AND_PASSWORD,
        }),
        async loginWithEmailAndPassword() {
            if (this.$v.$invalid) {
                return;
            }
            try {
                this.loggingIn = true;
                await this._logInWithEmailAndPassword({
                    email: !this.accessInfo || !this.accessInfo.grantee ? this.email : this.accessInfo.grantee.email,
                    password: this.password
                });
                if (this.onSubmit !== null) {
                    await this.onSubmit(AuthenticationMethod.email);
                }
            } catch (e) {
                switch (e) {
                    case "EMAIL_NOT_VERIFIED":
                        this.$notify({
                            group: "app",
                            type: "error",
                            text: this.$t("email_not_verified")
                        });
                        break;
                    case "auth/wrong-password":
                        this.$notify({
                            group: "app",
                            type: "error",
                            text: this.$t("wrong_password")
                        });
                        break;
                    case "auth/user-not-found":
                        this.$notify({
                            group: "app",
                            type: "error",
                            text: this.$t("user_not_found")
                        });
                        break;
                    case "auth/too-many-requests":
                        this.$notify({
                            group: "app",
                            type: "error",
                            text: this.$t("too_many_requests_logging_in")
                        });
                        break;
                    default:
                        this.$notify({
                            group: "app",
                            type: "error",
                            text: this.$t("unknown_error")
                        });
                }
            } finally {
                this.loggingIn = false;
            }
        },
        logInWithGoogle() {
            return this._socialLogIn(() => this._logInWithGoogle(), AuthenticationMethod.google);
        },
        logInWithApple() {
            return this._socialLogIn(() => this._logInWithApple(), AuthenticationMethod.apple);
        },
        async _socialLogIn(logInFunc, authMethod) {
            await logInFunc();
            if (this.onSubmit !== null) {
                await this.onSubmit(authMethod);
            }
        }
    },
    mounted() {
        if (this.accessInfo && this.accessInfo.grantee) {
            this.email = this.accessInfo.grantee.email;
        }
    },
    validations() {
        return {
            email: { required, email },
            password: { required, minLength: minLength(getGlobalConfiguration().password_min_length) },
        };
    },
};
</script>

<style lang="scss" scoped>
a.link-button {
    color: var(--primary);
    text-decoration: none;
    font-weight: 700;
}

.forgot-password {
    margin-top: .3rem;
    font-size: .7rem;
}

.signup-link {
    text-align: center;
}

.login-headline {
    margin-bottom: .2rem;
}
</style>
