<template>
    <div class="available-intranets-container">
        <div
            key="user"
            class="user-header centered"
            :class="loadingIntranets ? 'loading' : 'steady'"
        >
            <div
                class="avatar"
                :class="loadingIntranets ? 'avatar-xlarge' : 'avatar-large'"
            >
                <img
                    :src="currentUser.photoURL"
                    alt=""
                />
            </div>
            <transition
                name="fade"
                mode="out-in"
            >
                <h4 v-if="loadingIntranets">
                    {{ $t("hey", [currentUser.displayName]) }}
                </h4>
            </transition>
        </div>

        <AvailableIntranets
            v-if="currentUser"
            :key="'intranetlist'"
            class="available-intranets pbottom-small"
            :open-previous-intranet="openPreviousIntranet"
        />
    </div>
</template>

<script>
import {
    mapActions,
    mapGetters,
    mapState
} from "vuex";
import { LOGOUT_FROM_FIREBASE } from "@web/store/auth/actions";
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import { CURRENT_USER } from "@web/store/auth/getters";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import AvailableIntranets from "@web/views/portal/AvailableIntranets";

export default {
    name: "IntranetSelection",
    components: { AvailableIntranets },
    props: {
        openPreviousIntranet: Boolean,
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, ["loadingIntranets"]),
        ...mapGetters({
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
        }),
    },
    methods: {
        ...mapActions({
            logOut: AUTH_MODULE_NAME + LOGOUT_FROM_FIREBASE,
        }),
    }
};
</script>

<style lang="scss" scoped>
.user-header {
    width: 100%;
    padding: 1rem 1rem 3rem;
    position: absolute;
    top: 0;
    left: 0;
    transition: top 0.3s, transform 0.3s;

    h4 {
        margin: 0;
    }

    &.loading {
        top: 50vh;
        transform: translateY(-50%);
    }

    &.steady {
        top: 0;
        background: linear-gradient(
                180deg,
                var(--lowest-contrast, #{$off-white}) 0%,
                var(--lowest-contrast, #{$off-white}) 60%,
                rgba(var(--lowest-contrast, #{$off-white}), 0) 100%
        );
    }
}

.avatar.avatar-xlarge {
    width: 6rem;
    height: 6rem;
    margin-bottom: 0.5rem;
}

.available-intranets-container {
    width: 100%;
    overflow: auto;

    .available-intranets {
        height: 100%;
    }
}
</style>
