<script>
function getTransitionStyle(context) {
    return `height ${context.props.duration || ".3s"} ${context.props.timingFunction || "ease-in-out"}`;
}

// more information on how this works here: https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/
export default {
    name: "TransitionExpand",
    functional: true,
    render(createElement, context) {
        const data = {
            props: {
                name: "expand",
                duration: {
                    type: String,
                },
                timingFunction: {
                    type: String,
                },
            },
            on: {
                afterEnter(element) {
                    // eslint-disable-next-line no-param-reassign
                    element.style.height = "auto";
                    element.style.transition = "";
                },
                afterLeave(element) {
                    element.style.transition = "";
                },
                enter(element) {
                    const { width } = getComputedStyle(element);
                    element.style.transition = getTransitionStyle(context);
                    /* eslint-disable no-param-reassign */
                    element.style.width = width;
                    element.style.position = "absolute";
                    element.style.visibility = "hidden";
                    element.style.height = "auto";
                    /* eslint-enable */
                    const { height } = getComputedStyle(element);
                    /* eslint-disable no-param-reassign */
                    element.style.width = null;
                    element.style.position = null;
                    element.style.visibility = null;
                    element.style.height = 0;
                    /* eslint-enable */
                    // Force repaint to make sure the
                    // animation is triggered correctly.
                    // eslint-disable-next-line no-unused-expressions
                    getComputedStyle(element).height;
                    requestAnimationFrame(() => {
                        // eslint-disable-next-line no-param-reassign
                        element.style.height = height;
                    });
                },
                leave(element) {
                    const { height } = getComputedStyle(element);
                    element.style.transition = getTransitionStyle(context);
                    // eslint-disable-next-line no-param-reassign
                    element.style.height = height;
                    // Force repaint to make sure the
                    // animation is triggered correctly.
                    // eslint-disable-next-line no-unused-expressions
                    getComputedStyle(element).height;
                    requestAnimationFrame(() => {
                        // eslint-disable-next-line no-param-reassign
                        element.style.height = 0;
                    });
                },
            },
        };
        return createElement("transition", data, context.children);
    },
};
</script>

<style scoped>
* {
    will-change: height;
    transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000px;
}
</style>
