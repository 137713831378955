<template>
    <div class="padding-large content">
        <div class="centered">
            <h2 class="mbottom-xsmall">
                {{ $t("intranet_creation_success_headline", [currentUser.displayName]) }}
            </h2>
            <p>{{ $t("intranet_creation_success_subheadline") }}</p>
            <div class="the-hand">
                <HeyFiveInteraction @update:show="$emit('create')"/>
            </div>
            <p class="mtop-medium">
                {{ $t("social_notification_interaction_description_hey_five") }}
            </p>
            <app-button
                type="primary"
                @click.native="$emit('create')"
            >
                {{ $t("open_dashboard") }}
            </app-button>
        </div>
    </div>
</template>

<script>
import HeyFiveInteraction from "@web/components/social-notifications/HeyFiveInteraction";
import { mapGetters } from "vuex";
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import { CURRENT_USER } from "@web/store/auth/getters";

export default {
    name: "IntranetCreationSuccessHeyFiveScreen",
    components: { HeyFiveInteraction },
    computed: {
        ...mapGetters({
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
        }),
    },
};
</script>

<style lang="scss" scoped>
.the-hand {
    max-height: 25rem;
    max-width: 25rem;
    > * {
        transform-origin: 0 0;
        transform: scale(0.8);
    }
}
</style>
