<template>
    <div class="flex">
        <div class="left ptop-large pleft-large pright-large pbottom-medium">
            <slot name="left"></slot>
            <ProgressDots
                :count="progressDotCount"
                :completed="progressDotCompleted"
                :only-current="true"
                class="mtop-medium"
            />
        </div>
        <div class="right flex padding-large">
            <slot name="right"></slot>
        </div>
    </div>
</template>

<script>
import ProgressDots from "@web/components/ProgressDots";

export default {
    name: "SplitTutorialStep",
    components: { ProgressDots },
    props: {
        progressDotCount: Number,
        progressDotCompleted: Number,
    }
};
</script>

<style lang="scss" scoped>
.back {
    cursor: pointer;
    color: $highest-contrast;
}

.left,
.right {
    flex: 50%;
    width: 50vw;
    max-width: 32rem;
}

.right {
    background: $lowest-contrast;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: $spacing-medium;
}
</style>
