<template>
    <div class="success-box">
        <h3>{{ $t("signup_successful_headline") }}</h3>
        <div
            class="success-robot mbottom-small"
            v-html="require(`@web/assets/system/astrobot.svg`)"
        ></div>
        <span class="centered">{{ $t("signup_successful_mail_invite") }}</span>
    </div>
</template>

<script>
export default {
    name: "SignUpSuccessful",
};
</script>

<style lang="scss" scoped>
.success-robot {
    width: 10rem;
}

.success-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
