export enum AuthenticationMethod {
    email = "EMAIL", google = "GOOGLE", apple = "APPLE",
}

export const ACCESS_CREDENTIALS_COLLECTION_IDENTIFIER = "accessCredentials";

export interface IntranetAccessInfo {
    intranetUid: string,
    intranetName: string,
    intranetLogo: string,
    grantee?: IntranetAccessGranteeInfo,
}

export interface IntranetAccessGranteeInfo {
    email: string,
    /**
     * `null` if user has not logged in before
     */
    authMethod: AuthenticationMethod | null,
}

export interface IntranetAccessCredentials {
    accessCode: string | null,
}
