<template>
    <SplitTutorialStep
        :progress-dot-count="3"
        :progress-dot-completed="2"
        @cancel="resetColorsAndGoBack"
    >
        <template #left>
            <h2>
                {{ $t("create_workspace_theming_title", [workspaceName]) }}
            </h2>
            <p>{{ $t("create_workspace_theming_description") }}</p>
            <form
                v-if="!logoSrc"
                id="logo-upload"
                enctype="multipart/form-data"
            >
                <div
                    class="drop-zone padding-medium centered"
                    :class="{drag}"
                    @dragover.prevent.stop.capture="onDragEnter"
                    @dragleave.prevent.stop.capture="drag = false"
                    @drop.prevent.stop.capture="onDrop"
                >
                    <h3 class="mbottom-small">
                        {{ $t("place_logo") }}
                    </h3>
                    <p class="small-text">
                        {{ $t("logo_description") }}
                    </p>
                    <input
                        id="image-input"
                        ref="imageInput"
                        type="file"
                        class="hidden"
                        accept="image/png,image/jpeg"
                        method="post"
                        @change="onUpload"
                    />
                    <label
                        class="button outline"
                        for="image-input"
                    >
                        <Icon
                            name="upload"
                            size="small"
                        />
                        {{ $t("upload_logo") }}
                    </label>
                </div>
            </form>
            <div
                v-else
                class="logo-preview"
            >
                <img
                    :src="logoSrc"
                    alt=""
                />
                <div
                    class="trash-overlay"
                    @click="resetLogo"
                >
                    <Icon
                        name="trash"
                        color="var(--highest-contrast)"
                    />
                </div>
            </div>

            <div class="flex colors mbottom-medium">
                <color-picker
                    label="Primary Color"
                    :color="colors.primaryColor"
                    @input="colorPicked('primaryColor', $event)"
                />
                <color-picker
                    label="Secondary Color"
                    :color="colors.secondaryColor"
                    @input="colorPicked('secondaryColor', $event)"
                />
                <transition name="fade">
                    <div
                        v-if="autothemingBusy"
                        class="overlay"
                    >
                        <Spinner/>
                    </div>
                </transition>
            </div>
            <div class="flex">
                <app-button
                    center
                    type="outline primary"
                    @click="$emit('cancel')"
                >
                    <strong>{{ $t("back") }}</strong>
                </app-button>
                <app-button
                    type="primary"
                    :disabled="$v.$invalid"
                    :busy="busy || autothemingBusy"
                    center
                    @click="$emit('next')"
                >
                    <strong>{{ $t("next") }}</strong>
                </app-button>
            </div>
        </template>
        <template #right>
            <div
                class="skeleton"
                v-html="themingSkeleton"
            ></div>
        </template>
    </SplitTutorialStep>
</template>

<script>
import { validationMixin } from "vuelidate";
import themingSkeleton from "@web/assets/onboarding/skeleton_theming.svg";
import ColorPicker from "@web/views/intranet/admin/theming/ColorPicker.vue";
import SplitTutorialStep from "@web/components/onboarding/SplitTutorialStep";
import { themeService } from "@web/services";
import {
    helpers,
    required,
} from "vuelidate/lib/validators";
import { compressImage } from "@web/lib/image-compression";
import styleMixin from "@web/views/StyleMixin";
import Spinner from "@web/components/Spinner";
import { getGlobalConfiguration } from "@/global-config";
import { ThemeColors } from "@/services/ThemeColors";
import { textColor } from "@backend/theming/color_contrast";

const imageReader = new FileReader();
const hex = helpers.regex("hex", /^#[0-9A-F]{6}$/i);

export default {
    name: "IntranetThemingForm",
    components: { Spinner, ColorPicker, SplitTutorialStep },
    mixins: [validationMixin, styleMixin],
    props: {
        busy: Boolean,
        workspaceName: String,
        initialColors: Object,
        initialLogoBlob: Blob,
        initialLogoSrc: String,
    },
    data() {
        return {
            drag: false,
            logoSrc: this.initialLogoSrc,
            logoBlob: this.initialLogoBlob,
            autothemingBusy: false,
            mime: undefined,
            colors: this.initialColors || { ...ThemeColors.DEFAULT_COLORS() },
            themingSkeleton,
        };
    },
    methods: {
        update() {
            this.$emit("update", {
                colors: this.colors,
                logoBlob: this.logoBlob,
                logoSrc: this.logoSrc,
            });
        },
        colorPicked(colorName, color) {
            this.colors[colorName] = color;
            if (colorName === "primaryColor") {
                this.colors.sidebarColor = color;
                this.colors.sidebarActiveColor = color;
            }
            this.colors.primaryTextColor = textColor(this.colors.primaryColor, getGlobalConfiguration().theming_color_contrast_ratio_min);
            this.colors.secondaryTextColor = textColor(this.colors.secondaryColor, getGlobalConfiguration().theming_color_contrast_ratio_min);
            this.setIntranetColors(this.colors);
            this.update();
        },
        onDrop(event) {
            this.drag = false;
            const files = Array.from(event.dataTransfer.files);
            if (!["image/png", "image/jpeg"].includes(files[0].type)) return;
            if (files.length > 0) this.loadLogoFromFile(files[0]);
        },
        onUpload() {
            if (this.$refs.imageInput.files.length > 0) {
                this.loadLogoFromFile(this.$refs.imageInput.files[0]);
            }
        },
        loadLogoFromFile(file) {
            try {
                this.autothemingBusy = true;
                this.mime = file.type;
                imageReader.onload = this.processLogoBuffer;
                imageReader.readAsArrayBuffer(file);
            } catch (e) {
                console.error(e);
                this.$notify({
                    group: "app",
                    type: "error",
                    // TODO: Appropriate Errors
                    title: this.$t("attachment_upload_limit_reached_title"),
                    text: this.$t("attachment_upload_limit_reached_text"),
                });
            }
        },
        async processLogoBuffer() {
            const bigBlob = new Blob([imageReader.result], { type: this.mime });
            this.logoBlob = await compressImage(bigBlob, { maxWidth: 200, maxHeight: 200 });
            this.logoSrc = window.URL.createObjectURL(this.logoBlob);
            this.colors = await themeService.getSuggestedThemeColorsForImage(this.logoBlob);
            this.setIntranetColors(this.colors);
            this.autothemingBusy = false;
            this.update();
        },
        resetLogo() {
            this.logoBlob = undefined;
            this.logoSrc = undefined;
            this.resetColors();
        },
        onDragEnter(event) {
            const fileTypes = [...event.dataTransfer.items].map(item => item.type);
            this.drag = fileTypes.some((type) => type.includes("image"));
        },
        resetColors() {
            this.colors = { ...ThemeColors.DEFAULT_COLORS() };
            this.setIntranetColors(this.colors);
        },
        resetColorsAndGoBack() {
            this.$emit("cancel");
        },
    },
    validations() {
        return {
            colors: {
                primaryColor: { required, valid: hex },
                secondaryColor: { required, valid: hex },
            },
        };
    },
};
</script>

<style lang="scss" scoped>
.drop-zone {
    position: relative;
    border: 2px $low-contrast dashed;
    color: $medium-contrast;
    border-radius: $border-radius-medium;

    &.drag {
        border: 2px $high-contrast dashed;
    }
}

.logo-preview {
    border: 2px $low-contrast dashed;
    color: $medium-contrast;
    border-radius: $border-radius-medium;
    padding: $spacing-small;
    position: relative;
    overflow: hidden;
    text-align: center;

    img {
        max-width: 280px;
        height: 50px;
        object-fit: contain;
        border-radius: $border-radius-small;
    }

    &:hover {
        .trash-overlay {
            opacity: 1;
            pointer-events: all;
        }
    }

    .trash-overlay {
        width: 2.5rem;
        height: 2.5rem;
        padding: $spacing-medium;
        margin: $spacing-small;
        display: flex;
        opacity: 0;
        pointer-events: none;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        background: $lowest-contrast;
        transition: background .25s ease-in-out;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
            background: $low-contrast;
        }
    }
}

.colors {
    justify-content: space-between;
    position: relative;

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($white, $opacity-eighty);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.skeleton {
    border-radius: $border-radius-small;
    box-shadow: $depth-2;
}

::v-deep {
    .fill-primary {
        fill: $primary;
        transition: fill .5s ease-in-out;
    }

    .fill-secondary {
        fill: $secondary;
        transition: fill .5s ease-in-out;
    }

    .fill-bg {
        fill: $background;
    }

    .fill-medium {
        fill: $medium-contrast;
    }

    .fill-low {
        fill: $low-contrast;
    }

    .fill-lowest {
        fill: $lowest-contrast;
    }

    .stroke-bg {
        stroke: $background;
    }

    .stroke-lowest {
        stroke: $lowest-contrast;
    }
}
</style>
