<template>
    <div class="padding-large content centered flex-center">
        <div
            v-if="logoUrl"
            class="logo mbottom-medium"
        >
            <img
                :src="logoUrl"
                alt=""
            />
        </div>
        <h3 class="mbottom-xxsmall">
            {{ $t("intranet_creation_loading_headline", [workspaceName]) }}
        </h3>
        <p class="mbottom-medium">
            {{ $t("intranet_creation_loading_subheadline") }}
        </p>
        <onboarding-progress-bar :progress="progress" />
        <div class="action-container mtop-small">
            <transition name="fade">
                <p
                    :key="currentAction"
                    class="action"
                >
                    {{ currentAction }}
                </p>
            </transition>
        </div>
    </div>
</template>

<script>
import { wait } from "@web/lib/wait";
import OnboardingProgressBar from "@/components/onboarding/OnboardingProgressBar";

export default {
    name: "IntranetCreationLoadingScreen",
    components: { OnboardingProgressBar },
    props: {
        workspaceName: {
            type: String,
            required: true,
        },
        busy: {
            type: Boolean,
            required: true,
            default: true,
        },
        logoBlob: Blob,
    },
    data() {
        return {
            logoUrl: undefined,
            progress: 0,
            currentAction: "",
            steps: [
                {
                    action: this.$t("intranet_creation_loading_step1"),
                    dur: 2.5 + Math.random(),
                },
                {
                    action: this.$t("intranet_creation_loading_step2"),
                    dur: 2 + Math.random(),
                },
                {
                    action: this.$t("intranet_creation_loading_step3"),
                    dur: 2 + Math.random(),
                },
                {
                    action: this.$t("intranet_creation_loading_step4"),
                    dur: 1 + Math.random() * 0.2,
                },
            ],
        };
    },
    mounted() {
        if (this.logoBlob) {
            this.logoUrl = URL.createObjectURL(this.logoBlob);
        }
        this.triggerProgress();
    },
    methods: {
        async triggerProgress() {
            for (const step of this.steps) {
                this.currentAction = step.action;
                await wait(step.dur * 1000);
                this.progress += 0.333;
            }
            if (!this.busy) {
                this.$emit("create");
            } else {
                // If still busy call every 200 ms to check for completion
                await this.waitForIntranet();
            }
        },
        async waitForIntranet() {
            if (!this.busy) {
                this.$emit("create");
            } else {
                await wait(500);
                await this.waitForIntranet();
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.content {
    max-width: 64rem;
}

.logo {
    width: 200px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;

    img {
        max-height: 100%;
        max-width: 100%;
    }
}

.action-container {
    position: relative;
}

.action {
    color: $high-contrast;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
</style>
