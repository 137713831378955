import { textColor } from "@backend/theming/color_contrast";

export interface IntranetTheme {
    logo: string;
    colors: IntranetColors;
}

export interface IntranetColors {
    [key: string]: string;

    primaryColor: string;
    primaryTextColor: string;
    secondaryColor: string;
    secondaryTextColor: string;
    errorColor: string;
    errorTextColor: string;
    infoColor: string;
    infoTextColor: string;
    sidebarColor: string;
    sidebarTextColor: string;
    sidebarActiveColor: string;
}


interface GlobalConfigurationColors {
    theming_defaults_primary_hex: string,
    theming_defaults_secondary_hex: string,
    theming_defaults_error_hex: string,
    theming_defaults_info_hex: string,
}

export function defaultIntranetColors(globalConfiguration: GlobalConfigurationColors, minTextContrastRatio: number): IntranetColors {
    const primaryColor = globalConfiguration.theming_defaults_primary_hex;
    const secondaryColor = globalConfiguration.theming_defaults_secondary_hex;
    const errorColor = globalConfiguration.theming_defaults_error_hex;
    const infoColor = globalConfiguration.theming_defaults_info_hex;
    return  {
        primaryColor,
        primaryTextColor: textColor(primaryColor, minTextContrastRatio),
        secondaryColor,
        secondaryTextColor: textColor(secondaryColor, minTextContrastRatio),
        errorColor,
        errorTextColor: textColor(errorColor, minTextContrastRatio),
        sidebarActiveColor: "#00cc76",
        infoTextColor: "#222222",
        sidebarTextColor: "#222222",
        sidebarColor: "#ffffff",
        infoColor: infoColor,
    };

}

export const DEFAULT_LOGO_PATH = "default/logo/logo.png";
