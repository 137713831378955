<template>
    <div class="center-container">
        <div class="card depth-4 var-size">
            <transition name="fade">
                <join-or-create-intranet-form
                    v-if="!creationMode"
                    :cancelable="cancelable"
                    :busy="busy"
                    @join="join"
                    @create="creationMode = 'naming'"
                    @cancel="$emit('cancel')"
                />
                <create-intranet-form
                    v-else-if="creationMode === 'naming'"
                    :busy="busy"
                    :initial-workspace-name="workspaceName"
                    :initial-company-name="companyName"
                    :initial-company-size="companySize"
                    :initial-prefill="prefill"
                    @update="updateNamingData"
                    @next="creationMode = 'theming'"
                    @cancel="cancelIntranetCreation"
                />
                <intranet-theming-form
                    v-else-if="creationMode === 'theming'"
                    :busy="busy"
                    :initial-colors="colors"
                    :initial-logo-src="logoSrc"
                    :initial-logo-blob="logoBlob"
                    :workspace-name="workspaceName"
                    @update="updateThemingData"
                    @next="creationMode = 'invite'"
                    @cancel="creationMode = 'naming'"
                />
                <invite-by-mail-form
                    v-else-if="creationMode === 'invite'"
                    :busy="busy"
                    :workspace-name="workspaceName"
                    :initial-emails="emails"
                    :colors="colors"
                    :intranet-uid="createdIntranetId"
                    @update="updateEmails"
                    @next="proceedToLoading()"
                    @cancel="creationMode = 'theming'"
                />
                <IntranetCreationLoadingScreen
                    v-else-if="creationMode === 'loading'"
                    :busy="busy"
                    :workspace-name="workspaceName"
                    :logo-blob="logoBlob"
                    @create="creationMode = 'heyFive'"
                />
                <IntranetCreationSuccessHeyFiveScreen
                    v-else-if="creationMode === 'heyFive'"
                    :workspace-name="workspaceName"
                    @create="goToDashboard()"
                />
            </transition>
        </div>
    </div>
</template>

<script>
import JoinOrCreateIntranetForm from "@web/views/portal/JoinOrCreateIntranetForm";
import CreateIntranetForm from "@web/views/portal/onboarding/CreateIntranetForm";
import IntranetThemingForm from "@web/views/portal/onboarding/IntranetThemingForm";
import InviteByMailForm from "@web/views/portal/onboarding/InviteByMailForm";
import IntranetCreationLoadingScreen from "@web/views/portal/onboarding/IntranetCreationLoadingScreen";
import IntranetCreationSuccessHeyFiveScreen from "@web/views/portal/onboarding/IntranetCreationSuccessHeyFiveScreen";
import {
    codeBasedIntranetAccessService,
    hubspotService,
    intranetService,
    inviteService,
    themeService,
} from "@web/services";
import {
    mapActions,
    mapMutations,
} from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { RESET_INTRANET_MODULE } from "@web/store/intranet/mutations";
import { INVITE_MODULE_NAME } from "@web/store/invite/invite";
import { INVITE_USER_BY_MAIL } from "@web/store/invite/actions";
import { RELOAD_CURRENT_INTRANET } from "@web/store/intranet/actions";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import { blobToFile } from "@web/services/file-upload";
import { firebase } from "@web/firebase-instance";

export default {
    name: "AddIntranet",
    components: { IntranetCreationLoadingScreen, CreateIntranetForm, JoinOrCreateIntranetForm, IntranetThemingForm, InviteByMailForm, IntranetCreationSuccessHeyFiveScreen },
    props: {
        cancelable: Boolean,
    },
    data() {
        return {
            creationMode: undefined,
            busy: false,
            workspaceName: "",
            companyName: "",
            prefill: undefined,
            companySize: undefined,
            colors: undefined,
            logoSrc: undefined,
            logoBlob: undefined,
            createdIntranetId: undefined,
            emails: undefined,
        };
    },
    methods: {
        ...mapMutations({
            resetIntranets: INTRANET_MODULE_NAME + RESET_INTRANET_MODULE,
        }),
        ...mapActions({
            inviteUserByMail: INVITE_MODULE_NAME + INVITE_USER_BY_MAIL,
            reloadIntranet: INTRANET_MODULE_NAME + RELOAD_CURRENT_INTRANET,
        }),
        cancelIntranetCreation() {
            this.$modal.show("confirm-modal", {
                text: this.$t("onboarding_cancel_confirm"),
                warning: true,
                callback: (confirmed) => {
                    if (confirmed) {
                        this.reset();
                    }
                },
            });
        },
        reset() {
            this.creationMode = undefined;
            this.workspaceName = "";
            this.companyName = "";
            this.companySize = undefined;
            this.prefill = undefined;
            this.colors = undefined;
            this.logoSrc = undefined;
            this.logoBlob = undefined;
            this.createdIntranetId = undefined;
            this.emails = undefined;
        },
        notifyInvalidAccessCode() {
            this.$notify({
                group: "app",
                type: "error",
                text: this.$t("add_workspace_join_invalid_access_code"),
            });
        },
        async join(accessCodeInput) {
            this.busy = true;
            try {
                const accessData = this.tryToParseAccessUrl(accessCodeInput) || this.tryToParseAccessCode(accessCodeInput);
                if (!accessData) {
                    this.notifyInvalidAccessCode();
                    return;
                }
                const { intranetUid, accessCode } = accessData;
                try {
                    await codeBasedIntranetAccessService.joinWithAccessCode(intranetUid, accessCode);
                } catch (e) {
                    console.error(e);
                    this.notifyInvalidAccessCode();
                    return;
                }
                await this.$router.push({ name: "dashboard", params: { intranetId: intranetUid } });
            } finally {
                this.busy = false;
            }
        },
        tryToParseAccessUrl(maybeAccessUrl) {
            try {
                const url = new URL(maybeAccessUrl);
                if (!url.searchParams.has("intranetUid") || !url.searchParams.has("accessCode")) {
                    return null;
                }
                return {
                    intranetUid: url.searchParams.get("intranetUid"),
                    accessCode: url.searchParams.get("accessCode"),
                };
            } catch (e) {
                return null;
            }
        },
        tryToParseAccessCode(maybeAccessCode) {
            const splitAccessCode = maybeAccessCode.split("/");
            if (splitAccessCode.length !== 2) {
                return null;
            }
            return {
                intranetUid: splitAccessCode[0],
                accessCode: splitAccessCode[1],
            };
        },
        async updateNamingData(companyName, workspaceName, prefill, companySize) {
            this.companyName = companyName;
            this.workspaceName = workspaceName;
            this.prefill = prefill;
            this.companySize = companySize;
        },
        updateThemingData({ colors, logoBlob, logoSrc }) {
            this.colors = colors;
            this.logoBlob = logoBlob;
            this.logoSrc = logoSrc;
        },
        updateEmails(emails) {
            this.emails = emails;
        },
        async proceedToLoading() {
            this.busy = true;
            this.creationMode = "loading";
            try {
                this.createdIntranetId = await intranetService.registerNewIntranet(this.workspaceName, this.prefill);
                console.log(this.createdIntranetId);
                const currentUser = firebase.auth().currentUser;
                const userMail = currentUser.email || undefined;
                const eventName = this.prefill
                    ? getGlobalConfiguration().analytics_event_name_demo_intranet_created
                    : getGlobalConfiguration().analytics_event_name_intranet_created;
                analytics.log(eventName, {
                    intranetUid: this.createdIntranetId,
                    userMail,
                    companySize: this.companySize,
                });
                analytics.log(getGlobalConfiguration().analytics_event_name_finished_intranet_creation, {
                    intranetUid: this.createdIntranetId,
                    prefilled: this.prefilled,
                    withLogo: this.logoBlob === undefined,
                });
                hubspotService.submitHubspotForm({
                    intranetUid: this.createdIntranetId,
                    pageName: document.title,
                    pageUri: document.location.href,
                    intranetName: this.workspaceName,
                    companyName: this.companyName,
                    currentUser,
                    isDemonstrationIntranet: this.prefill,
                    companySize: this.companySize,
                }).then(() => {
                    console.log("Successfully submitted hubspot form data");
                }).catch((e) => console.error("Error submitting hubspot data: ", e));
                if (this.logoBlob) {
                    const logoFile = blobToFile(this.logoBlob, `logo_${encodeURI(this.workspaceName)}`);
                    const logoUrl = await themeService.uploadNewLogo(this.createdIntranetId, currentUser.uid, logoFile);
                    await themeService.saveLogo(this.createdIntranetId, logoUrl);
                }
                if (this.colors) await themeService.saveThemeSettings(this.createdIntranetId, this.colors);
                if (this.emails) {
                    for (const email of this.emails) {
                        if (email.length) await inviteService.createMailInviteForIntranet(this.createdIntranetId, email);
                    }
                }
            } catch (e) {
                console.log(e);
                this.creationMode = undefined;
                this.$notify({
                    group: "app",
                    type: "error",
                    text: this.$t("creation_of_intranet_failed"),
                });
            } finally {
                this.busy = false;
            }
        },
        async goToDashboard() {
            this.resetIntranets();
            await this.reloadIntranet();
            await this.$router.push({ name: "dashboard", params: { intranetId: this.createdIntranetId } });
        },
    },
};
</script>

<style lang="scss" scoped>
.card {
    position: relative;
}

.var-size {
    transition: width .3s $ease-in-out-quad, height .3s $ease-in-out-quad;
}

.fade-leave-active {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
